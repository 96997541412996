<template>
  <!-- 搜索页面 -->
  <div class="standard">
    <div class="top">
      <div class="top_search">
        <input
          type="text"
          @keyup.enter="courseSearch"
          class="top_search_inp"
          v-model="courseVal"
        />
        <div class="top_search_but" @click="courseSearch">搜索</div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom_item">
        <div
          class="search_item"
          v-for="search in searchList"
          :key="search.id"
          @click="
            $router.push(`/courlist?tid=${search.type_id}&tn=${search.title}`)
          "
        >
          <img
            class="search_item_img"
            :src="'http://tot.xlteacher.cn' + search.pic"
            alt=""
          />
          <p class="search_item_text">{{ search.title }}</p>
        </div>
      </div>
      <el-pagination
        v-if="totalNum > 10"
        background
        layout="prev, pager, next"
        :total="totalNum"
        class="page"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      totalNum: 1,
      pageSize: 1,
      searchList: [],
      courseVal: "",
      currentPage: 1, //默认
      page_size: 10,
    };
  },
  mounted() {
    function bodyScale() {
      var devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
      var scale = devicewidth / 1920; // 分母——设计稿的尺寸
      document.body.style.zoom = scale; //放大缩小相应倍数
    }
    bodyScale();
    this.searchData();
  },
  components: {},
  methods: {
    searchData() {
      const { type, search } = this.$route.query;
      let fd = new FormData();
      fd.append("type", type);
      fd.append("search", search);
      fd.append("page_size", this.page_size);
      this.axios.post("/index/search_list", fd).then((res) => {
        console.log(res);

        this.totalNum = res.data.count;
        this.pageSize = Number(res.data.page_size);
        this.searchList = res.data.list;
      });
    },
    courseSearch() {
      this.$router.push(`/search?type=1&search=${this.courseVal}`);
      this.$router.go(0);
      // let fd = new FormData();
      // fd.append("type", 1);
      // fd.append("search", this.courseVal);
      // this.axios.post("/index/search_list", fd).then((res) => {
      //   this.totalNum = res.data.count;
      //   this.pageSize = res.data.page_size;
      //   this.searchList = res.data.list;
      // });
    },
    //搜索更改页码
    handleCurrentChange(page) {
      this.currentPage = page;
      let fd = new FormData();
      fd.append("page", page);
      fd.append("page_size", this.page_size);
      this.axios.post("index/my_course", fd).then((res) => {
        this.totalNum = res.data.count;
        this.pageSize = Number(res.data.page_size);
        this.searchList = res.data.list;
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    console.log(to);
    next((vm) => {
      console.log(vm);
      vm.courseVal = vm.$route.query.search;
    });
  },
};
</script>

<style lang="scss">
/* el-table表格去掉横线 */
.bottom {
  .el-table__row > td {
    border: none;
  }
  .el-table::before {
    height: 0px;
  }
}
//分页的公共样式
.el-pagination {
  text-align: center;
  margin-top: 30px;
  .btn-prev,
  .btn-next {
    background-color: #fff !important;
    border: 1px solid #66a4ff;
    border-radius: 3px !important;
    padding: 5px 10px !important;
    box-sizing: border-box !important;
    &:hover {
      color: #66a4ff;
    }
    span {
      line-height: 1;
    }
  }
  .el-pager {
    li {
      font-weight: normal;
    }
  }
}
</style>
<style lang="scss" scoped>
body {
  background: #f8f8f8;
}
.standard {
  min-height: calc(100vh - 80px);
  .top {
    width: 100%;
    height: 200px;
    background: #eaeefc;
    display: flex;
    justify-content: center;
    align-items: center;
    .top_search {
      width: 35%;
      height: 59px;
      background: #fff;
      display: flex;
      justify-content: flex-end;
      .top_search_inp {
        width: 100%;
        outline: none;
        font-size: 26px;
        border: 0 solid;
        font-weight: 200;
        padding-left: 20px;
      }
      .top_search_but {
        width: 22%;
        height: 100%;
        line-height: 350%;
        background: #83b1fc;
        font-size: 16px;
        text-align: center;
        color: #ffffff;
      }
    }
  }
  .bottom {
    width: 63.5%;
    height: 100%;
    margin: auto;
    margin-top: 30px;
    ///background: lightgoldenrodyellow;
    //background: #fff;
    .bottom_item {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .search_item_img {
        width: 225px;
        height: 144px;
        margin-left: 18px;
        cursor: pointer;
      }
      .search_item_text {
        margin-left: 20px;
        margin-top: 10px;
      }
    }
    .page {
      text-align: center;
      margin-top: 30px;
      padding-bottom: 35px;
    }
  }
}
</style>
;
